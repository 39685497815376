import React from 'react';
import { Link } from 'react-router-dom';
import fullTextLogo from '../assets/full_text_osos_logo.png';

const Navbar = () => {
    return (
        <div>
            {/* navvbar will go here */}
            <nav className="bg-gray-100" id="site-header">
                <div className="max-w-6xl mx-auto px-4">
                    <div className="flex justify-between">

                        <div className="flex space-x-16">
                            {/* logo */}
                            <div>
                                <Link to="/" className="flex items-center px-2 text-gray-700 hover:text-gray-900">
                                    <img alt="logo" className="flex w-1/2 md:w-1/4 h-1/6 items-left px-2 text-gray-700 hover:text-gray-900" src={fullTextLogo} ></img>
                                </Link>
                            </div>

                            {/* primary nav */}
                            {/* later we'll add more links here to the nav, but for now it can be verbiage TBD */}
                            <div className="hidden md:flex items-center space-x-1">
                                <Link to="/" className="block py-3 px-4 text-lg">Home</Link>
                            </div>
                        </div>

                        {/* secondary nav */}
                        <div className="hidden md:flex items-center space-x-1">

                        </div>

                        {/* mobile button goes here */}
                        <div className="md:hidden flex items-center">
                            <button className="mobile-menu-button">
                                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </button>
                        </div>

                    </div>
                </div>

                {/* mobile menu */}
                <div className="mobile-menu text-white text-lg hidden md:hidden">
                    <Link to="/" className="block py-3 px-4">Home</Link>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;