// static img imports
import backgroundImage from '../assets/benjamin-child-GWe0dlVD9e0-unsplash.jpg';
import remoteMeetingImg from '../assets/undraw_remote_meeting_cbfk.png';
import coderImg from '../assets/undraw_proud_coder_7ain.png';
import aboutUsImg from '../assets/brad-knight-huWlb1NP67w-unsplash.jpg';
import { Link } from 'react-router-dom';
import GridCard from './GridCard';
import Bio from './Bio';

// fontawesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Home() {
    return (
        <div>
            {/* Main Image */}
            <div
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                }} className="py-8 md:py-16 lg:py-32 xl:py-64  bg-no-repeat bg-center bg-cover bg-opacity-25 bg-gray-100 bg-fixed">

                <div className="container mx-auto w-auto md:w-3/5 lg:w-2/5 p-3 bg-white bg-opacity-50">

                    <h2 className="text-xl md:text-3xl font-bold mb-2 text-gray-900 text-center">
                        Providing innovative software for tomorrow's challenges.
                    </h2>
                </div>
            </div>

            {/* Grid wrapper */}
            <div className="flex flex-wrap item-container justify-center">
                {/* Grid Column */}
                <GridCard title="Collaboration" image_src={remoteMeetingImg}>
                    We work on determining the needs for your projects. Successful projects begins with understanding the essential problem to be solved. <b>We are not taking on client work at this time.</b>
                </GridCard>

                {/* Grid Column */}
                <GridCard title="Products" image_src={coderImg}>
                    We are beginning development on a few new products, and as soon as they ready - we'll share them with you.
                </GridCard>
            </div>

            <div>
                {/* About Us Image */}
                <div
                    style={{
                        backgroundImage: `url(${aboutUsImg})`,
                        // backgroundPositionY: -500
                    }} className="mt-4 md:mt-8 py-12 md:py-24 xl:pb-64 bg-no-repeat bg-cover bg-opacity-25 bg-gray-100 bg-bottom">

                    <div className="container mx-auto mt-8 w-auto md:w-3/5 lg:w-2/5 p-3 bg-white bg-opacity-50">

                        <h2 className="text-3xl font-bold mb-2 text-gray-900 text-center">
                            About Us
                        </h2>
                        <p>At Osos, we take pride in our combined knowledge in the development field and shared passion for technology. We count on attention to detail, effective communication, and our ability to provide high quality, individualized solutions.</p>
                    </div>
                </div>

                <div className="hidden md:block">
                    <div className="flex mx-auto pt-16 text-xl font-bold mb-2 text-gray-900 text-center justify-center">
                        <h3>Who We Are</h3>
                    </div>
                    <hr className="rounded w-1/2 flex mx-auto justify-center"></hr>

                    <div className="grid grid-cols flex mx-auto mt-6 justify-center justify-items-left flex w-3/4">
                        <Bio name="Chris Martinez">
                            <p>Chris has been working with the web since 1998 and has had the opportunity to work on a
                                variety of projects with some amazing clients ranging from AutoZone to the U.S. Army
                                Corps
                                of Engineers. He has experience with a variety of industries including corporate,
                                government, and most recently, higher education.</p>

                            <p className="my-4">Chris heads up development and technology for Osos Consulting, LLC. His
                                favorite quote is
                                “The heart of design is communication: defining a problem and creating a solution that
                                balances pure information with an aesthetic that gives the message voice.” – Jeffrey
                                Veen
                                1998. Chris focuses on finding the best solution to client problems.</p>
                        </Bio>

                        <Bio name="Anthony Garza">
                            <p>Anthony has been working professionally in software development since 2017. In that time
                                he
                                has had the chance to work as both a software tester and as a software developer. He
                                also
                                has relevant experience in web application conceptualization. Above all, he understands
                                that
                                meaningful and lasting software solutions are marked by the positive impact it has for
                                those
                                who use it.</p>

                            <p className="my-4">Anthony is a part of the development team at Osos. His current
                                day-to-day responsibilities
                                are full-stack development work on new projects here at Osos.</p>
                        </Bio>
                    </div>
                </div>

                <div className="flex mx-auto pt-16 text-xl font-bold mb-2 text-gray-900 text-center justify-center">
                    <h3>Want to learn more?</h3>
                </div>
                <div className="py-4 text-center">
                    <Link
                        to='/'
                        onClick={(e) => {
                            window.location = "mailto:info@ososconsulting.com";
                            e.preventDefault();
                        }}
                    >
                        <button className="site-blue-background text-white font-bold py-4 px-12 mx-12 space-x-3 rounded-full">
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span>Contact Us</span>
                        </button>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default Home;