import React, { useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer.js'
import Home from './components/Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {


  useEffect(() => {
     // grab everything we need
     const btn = document.querySelector("button.mobile-menu-button");
     const menu = document.querySelector(".mobile-menu");
 
     // add event listeners
     if (btn) {
       btn.addEventListener("click", () => {
         menu.classList.toggle("hidden");
       });
     }
  });

  return (
    <div className="App">
      <Router>
        <Navbar/>
        {/* content will go here */}
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
