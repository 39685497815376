import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {

    return (
        <div>
            {/* navvbar will go here */}
            <footer id="footer">

                <div className="container mx-auto px-6 py-5 flex justify-between items-center">

                    {/*
                    // todo : add analytics & create privacy page
                    <Link to="/" className="footer text-white">Privacy Policy</Link>
                    */}
                </div>
                {/* sticky icon to return to top of page*/}
                <Link to="#site-header" className="scrollTop">
                    <span>
                        <FontAwesomeIcon className="text-yellow-600 upIcon" icon={faArrowAltCircleUp} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} />
                    </span>
                </Link>

            </footer>

        </div>
    );
}

export default Footer;