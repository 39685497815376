const GridCard = (props) => {
    return (
        <div className="w-full flex flex-col py-4 sm:w-1/2 lg:w-1/2 items-center">
            <div className="flex-1 px-10 py-10 bg-white rounded-lg shadow-lg card">
                <div className="imgBox">
                    <img alt="logo" className="flex px-2 text-gray-700 hover:text-gray-900" src={props.image_src}></img>
                </div>
                <div className="px-6 py-2">
                    <div className="font-bold text-xl mb-2 text-center">{props.title}</div>
                    <p className="text-gray-700 text-base card-text">
                        {props.children}
                    </p>
                </div>
            </div>
        </div>
    );
}
export default GridCard;